import {
  IHeaderParams,
  IServerSideSelectionState,
  SelectionChangedEvent,
} from "@ag-grid-community/core";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { HexCheckbox } from "../../../../hex-components/HexCheckbox.js";
import { SCREEN_READER_ONLY_CLASSNAME } from "../../constants.js";

export const BulkActionsCheckboxHeader: React.ComponentType<IHeaderParams> =
  React.memo(function BulkActionsCheckboxHeader(props) {
    const [state, setState] = useState<
      "unchecked" | "indeterminate" | "checked"
    >("unchecked");

    useEffect(() => {
      const onSelectionChanged = (event: SelectionChangedEvent): void => {
        const selectionState = event.api.getServerSideSelectionState();
        const { selectAll, toggledNodes } =
          selectionState as IServerSideSelectionState;

        if (selectAll && toggledNodes.length === 0) {
          setState("checked");
        } else if (selectAll && toggledNodes.length > 0) {
          setState("indeterminate");
        } else {
          setState("unchecked");
        }
      };

      props.api.addEventListener("selectionChanged", onSelectionChanged);
      return () =>
        props.api.removeEventListener("selectionChanged", onSelectionChanged);
    }, [props.api, state]);

    const handleCheck = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
          props.api.selectAll();
        } else {
          props.api.deselectAll();
        }
      },
      [props.api],
    );

    // Render an invisible header if canSelectAll is disabled
    if (!props.context.canSelectAll) {
      return <span className={SCREEN_READER_ONLY_CLASSNAME}>Selected</span>;
    }

    return (
      <CheckboxWrapper>
        <HexCheckbox
          checked={state === "checked"}
          indeterminate={state === "indeterminate"}
          onChange={handleCheck}
          // eslint-disable-next-line react/jsx-no-bind -- no need to memo
          onClick={(evt) => {
            // blueprint checkboxes end up emitting two native click events
            // one on the actual input and one on the label
            // so we need to prevent one of them so we don't immediately deselect
            // @see https://github.com/palantir/blueprint/issues/3466
            //
            // the actual click event is handled by `handleOnCellClicked` in ProjectsTable
            evt.preventDefault();
          }}
        />
      </CheckboxWrapper>
    );
  });

const CheckboxWrapper = styled.div`
  margin-left: 11px;
`;
